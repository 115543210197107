import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
var fonts = {
  mono: "'Menlo', monospace"
};
var breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em'
});
var theme = extendTheme({
  colors: {
    black: '#16161D'
  },
  fonts: fonts,
  breakpoints: breakpoints
});
export default theme;